'use client'

import { usePathname } from 'next/navigation'

import { jsonLdBreadcrumb, jsonLdOrganization, jsonLdSearchAction } from '@/config/schema'

export default function JsonLdScripts() {
  const pathname = usePathname()
  const validPaths = ['/', '/contact-us', '/about-us', '/privacy-policy']
  const comicDetailRegex = /^\/manga\/[a-z0-9-]+$/
  return (
    <head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdBreadcrumb) }}
      />
      {validPaths.includes(pathname) || comicDetailRegex.test(pathname) ? (
        <>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(jsonLdSearchAction),
            }}
          />
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(jsonLdOrganization),
            }}
          />
        </>
      ) : null}
    </head>
  )
}
