'use client'

import { useSearch } from '@/hooks'
import { Search, XIcon } from 'lucide-react'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'

export const SearchInput = ({
  auto = true,
  value,
  className,
  onChange,
  onKeyDown,
  name = 'q',
  type = 'text',
  placeholder = 'Search manga...',
}) => {
  const { term, handleChange, handleKeyDown, clearSearch } = useSearch(auto)

  return (
    <div className="relative flex items-center border-b-[3px] border-[#fd5e1e] w-full">
      <Search className="absolute left-4 size-4 text-white" />
      <Input
        name={name}
        type={type}
        value={value ?? term}
        onChange={onChange ?? handleChange}
        onKeyDown={onKeyDown ?? handleKeyDown}
        placeholder={placeholder}
        className={cn('px-10 text-sm text-white placeholder:text-white', className)}
      />
      {(term || value) && (
        <Button size="icon" variant="ghost" className="absolute right-0" onClick={clearSearch}>
          <XIcon className="size-3" />
        </Button>
      )}
    </div>
  )
}
