export const jsonLdBreadcrumb = {
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      position: 1,
      name: 'Home',
      item: 'https://mangakaklotx.com',
    },
    {
      '@type': 'ListItem',
      position: 2,
      name: "✅There're no Ads",
      item: 'https://mangakaklotx.com/hot',
    },
  ],
}

export const jsonLdSearchAction = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  url: 'https://mangakaklotx.com',
  name: 'Mangakakalot X',
  potentialAction: {
    '@type': 'SearchAction',
    target: 'https://mangakaklotx.com/manga?keyword={search_term_string}',
    'query-input': 'required name=search_term_string',
  },
}

export const jsonLdOrganization = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Mangakakalot X',
  image: 'https://mangakaklotx.com/_next/image?url=%2Fimages%2Flogo.webp&w=96&q=100',
  url: 'https://mangakaklotx.com',
  sameAs: [
    'https://www.facebook.com/mangakaklotx',
    'https://www.tumblr.com/mangakaklotx',
    'https://mangakaklotx.blogspot.com/',
    'https://medium.com/@mangakaklotx',
    'https://www.pinterest.com/mangakaklotx/',
    'https://500px.com/p/mangakaklotx',
    'https://x.com/mangakaklotx',
    'https://www.threads.net/@mangakaklotx',
    'https://www.instagram.com/mangakaklotx',
  ],
}
