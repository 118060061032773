'use client'

import * as React from 'react'
import Link from 'next/link'

import { cn } from '@/lib/utils'
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from '@/components/ui/navigation-menu'

export function SiteNav({ genres = [] }) {
  return (
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem className="mr-[10px]">
          <Link href="/hot" legacyBehavior passHref>
            <NavigationMenuLink className={navigationMenuTriggerStyle()}>HOT</NavigationMenuLink>
          </Link>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuTrigger>GENRES</NavigationMenuTrigger>
          <NavigationMenuContent>
            <div className="grid grid-cols-2 w-60 max-h-96 overflow-auto gap-3 p-4 md:w-[500px] md:grid-cols-6 lg:w-[800px] ">
              {genres.map((genre) => (
                <div key={genre.id}>
                  <NavigationMenuLink asChild>
                    <Link
                      href={`/genres/${genre.slug}`}
                      className="line-clamp-1 rounded-sm p-1 text-center"
                    >
                      {genre.name}
                    </Link>
                  </NavigationMenuLink>
                </div>
              ))}
            </div>
          </NavigationMenuContent>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  )
}
