import(/* webpackMode: "eager", webpackExports: ["default"] */ "/www/wwwroot/mangakakalot/node_modules/holy-loader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/www/wwwroot/mangakakalot/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/www/wwwroot/mangakakalot/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/www/wwwroot/mangakakalot/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/www/wwwroot/mangakakalot/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/www/wwwroot/mangakakalot/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/www/wwwroot/mangakakalot/src/components/custom/logout-button.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/www/wwwroot/mangakakalot/src/components/JsonLdScripts.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollTop"] */ "/www/wwwroot/mangakakalot/src/components/scroll-top.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchInput"] */ "/www/wwwroot/mangakakalot/src/components/search-input.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["SiteNav"] */ "/www/wwwroot/mangakakalot/src/components/site-nav.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/www/wwwroot/mangakakalot/src/components/theme-provider.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeToggle"] */ "/www/wwwroot/mangakakalot/src/components/theme-toggle.jsx");
;
import(/* webpackMode: "eager" */ "/www/wwwroot/mangakakalot/src/components/ui/sheet.jsx");
