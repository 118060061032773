'use client'

import { logoutAction } from '@/data/actions/auth-actions'
import { Loader2, LogOut } from 'lucide-react'
import { useFormStatus } from 'react-dom'

import { cn } from '@/lib/utils'

function LogoutButtonContent({ className }) {
  const { pending } = useFormStatus()

  return (
    <button
      type="submit"
      title="Logout"
      className={cn('w-6 h-6 hover:text-primary relative', className)}
      disabled={pending}
    >
      {pending ? <Loader2 className="animate-spin" /> : <LogOut />}
    </button>
  )
}

export function LogoutButton({ className }) {
  return (
    <form action={logoutAction}>
      <LogoutButtonContent />
    </form>
  )
}
